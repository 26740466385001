<template>
  <div>
    <div class="content">

      <div class="contentR">
        <div class="prompt">
          <img src="../../assets/images/LTicon.png" alt="">
          <div class="promptText">维修记录</div>
        </div>
        <div class="searchContent">
          <div class="search">
            <div class="formList">
              <el-form :inline="true" :model="formInline" class="demo-form-inline">
                <el-form-item label="变配电站：">
                  <el-select @focus="openDialog" class="custom-select" :popper-append-to-body="false"
                    v-model="formInline.updateTime" placeholder="请选择">
                    <el-option label="全部" value="shanghai"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="任务状态：">
                  <el-checkbox-group v-model="formInline.checkList">
                    <el-checkbox label="执行中"></el-checkbox>
                    <el-checkbox label="已完成"></el-checkbox>
                    <el-checkbox label="已终止"></el-checkbox>
                  </el-checkbox-group>
                </el-form-item>

                <!-- <el-form-item label="手机号：">
                  <el-input v-model="formInline.content" placeholder="请输入手机号" class="custom-input"></el-input>
                </el-form-item> -->
                <el-form-item label="计划开始时间：">
                  <el-date-picker v-model="formInline.content" :append-to-body="false" type="date"
                    placeholder="选择日期"></el-date-picker> <span> ~
                  </span>
                  <el-date-picker v-model="formInline.createBy" :append-to-body="false" type="date"
                    placeholder="选择日期"></el-date-picker>
                </el-form-item>
                <el-form-item label="任务结果：">
                  <el-select class="custom-select" :popper-append-to-body="false" v-model="formInline.updateTime"
                    placeholder="请选择">
                    <el-option label="全部" value="shanghai"></el-option>
                    <el-option label="按时完成" value="beijing"></el-option>
                    <el-option label="超时完成" value="1"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="负责人：">
                  <el-input v-model="formInline.title" placeholder="请输入负责人名称" class="custom-input"></el-input>
                </el-form-item>

                <el-form-item>
                  <div class="newView">
                    <el-button type="primary" @click="onSubmit" icon="el-icon-search" class="custom-button">查询</el-button>
                    <el-button type="primary" @click="replyDialog" icon="el-icon-download"
                      class="custom-button">导出</el-button>
                    <!-- <el-button type="primary" @click="replyDialog(0)" icon="el-icon-s-order"
                      class="custom-button">任务发布</el-button> -->
                  </div>

                </el-form-item>
              </el-form>
            </div>
            <div class="newView">
            </div>
          </div>
        </div>
        <div class="table">
          <el-table border :data="tableData" style="width: 100%" class="custom-table">
            <el-table-column align="center" label="序号" type="index" width="150">
            </el-table-column>
            <el-table-column align="center" prop="name" label="变配电站" width="200">
            </el-table-column>
            <el-table-column align="center" prop="model" label="维修任务编号" :fluid="true">
            </el-table-column>
            <el-table-column align="center" prop="number" label="负责人" width="300">
            </el-table-column>
            <el-table-column align="center" prop="number2" label="执行人" width="300">
            </el-table-column>
            <el-table-column align="center" prop="email" label="巡检开始时间" width="200">
            </el-table-column>
            <el-table-column align="center" prop="email1" label="巡检结束时间" width="200">
            </el-table-column>
            <el-table-column align="center" prop="email2" label="任务状态" width="200">
            </el-table-column>
            <el-table-column align="center" prop="email3" label="执行结果" width="200">
            </el-table-column>
            <el-table-column align="center" prop="email4" label="巡检项数" width="200">
            </el-table-column>
            <el-table-column align="center" prop="email5" label="缺陷未处理数" width="200">
            </el-table-column>
            <el-table-column align="center" prop="data" fixed="right" label="操作" width="200">
              <template slot-scope="scope">
                <el-button @click="executionDialog(scope.row)" type="text">执行情况</el-button><span>|</span>
                <el-button @click="openViewDialog(scope.row, 1)" type="text">维修明细</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination">
          <div class="btn" @click="startHome">首页</div>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="prev, pager, next, sizes"
            :prev-text="prevButtonText" :next-text="nextButtonText" prev-button-class="custom-prev-button"
            next-button-class="custom-next-button" :total="total">
          </el-pagination>
          <div class="btn endBtn" @click="endHome">尾页</div>
          <div class="paginationEnd">
            <div>跳</div>至
            <el-input size="mini" @change="paginationEnd" class="paginationEndIpt"
              v-model.number="paginationEndIpt"></el-input>
            <div>页</div>
            <div class="btn confirm" @click="jumpPage">确定</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 导出 -->
    <el-dialog title="导出" :visible.sync="centerDialogVisible" width="20%">
      <div class="deleteCenter">
        确定要下载该文件吗？
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button class="dialogBtn" @click="centerDialogVisible = false">取 消</el-button>
        <el-button class="dialogBtn" type="primary" @click="centerDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 维修明细 -->
    <el-dialog title="维修明细" :visible.sync="replyDialogVisible" width="40%">
      <div class="dialogHeader">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="维修说明：" prop="">
            <el-input  type="textarea" :rows="2" placeholder="计划内容" v-model="ruleForm.dictName" class="my-custom custom-input">
            </el-input>
          </el-form-item>
          <el-row>
            <el-col :span="12">
              <el-form-item label="维修执行结果" prop="">
                <el-input placeholder="任务开始时间" v-model="ruleForm.dictName" class="my-custom custom-input">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="维修申报费用：" prop="">
                <el-input placeholder="任务结束时间" v-model="ruleForm.dictName" class="my-custom custom-input">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item label="维修工作明细：" prop="">
            <div class="defect">
              <!-- <div class="defectTitle"></div> -->
              <div class="defectContent">
               <div class=""> 维修工作明细维修工作明细维修工作明细维修工作明细维修工作明细维修工作明细</div>
                <img src="../../assets/images/defect.png" alt="">
              </div>
            </div>
          </el-form-item> 
          <el-form-item label="任务备注：" prop="">
            <div class="defect">
              <!-- <div class="defectTitle"></div> -->
              <div class="defectContent">
               <div class=""> 备注信息备注信息备注信息备注信息备注信息</div>
                <img src="../../assets/images/defect.png" alt="">
              </div>
            </div>
          </el-form-item> 

        </el-form>
      </div>
      <!-- <span slot="footer" class="dialog-footer">

        <el-button class="dialogBtn" @click="replyDialogVisible = false">取 消</el-button>
        <el-button class="dialogBtn" type="primary" @click="replyDialogVisible = false">确 定</el-button>
      </span> -->
    </el-dialog>
    <!--执行情况  -->
    <el-dialog title="任务详情" :visible.sync="implementDialogVisible" width="40%">
      <div class="dialogHeader">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-row>
            <el-col :span="12">
              <el-form-item label="标题名称：" prop="">
                <el-input placeholder="标题名称内容" v-model="ruleForm.dictName" class="my-custom custom-input">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="任务单号：" prop="">
                <el-input placeholder="任务单号" v-model="ruleForm.dictName" class="my-custom custom-input">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="变电站名称：" prop="">
                <el-input placeholder="变电站名称" v-model="ruleForm.dictName" class="my-custom custom-input">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="创建人：" prop="">
                <el-input placeholder="创建人" v-model="ruleForm.dictName" class="my-custom custom-input">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="执行人：" prop="">
                <el-input placeholder="执行人" v-model="ruleForm.dictName" class="my-custom custom-input">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="执行状态：" prop="">
                <el-input placeholder="执行状态" v-model="ruleForm.dictName" class="my-custom custom-input">
                </el-input>
              </el-form-item>
            </el-col>
           
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="计划开始时间：" prop="">
                <el-input placeholder="计划开始时间" v-model="ruleForm.dictName" class="my-custom custom-input">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="计划结束时间：" prop="">
                <el-input placeholder="计划结束时间" v-model="ruleForm.dictName" class="my-custom custom-input">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="任务开始时间：" prop="">
                <el-input placeholder="任务开始时间" v-model="ruleForm.dictName" class="my-custom custom-input">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="任务结束时间：" prop="">
                <el-input placeholder="任务结束时间" v-model="ruleForm.dictName" class="my-custom custom-input">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="任务内容：" prop="">
            <el-input  type="textarea" :rows="2" placeholder="计划内容" v-model="ruleForm.dictName" class="my-custom custom-input">
            </el-input>
          </el-form-item>
          <el-row>
            <el-col :span="12">
              <el-form-item label="审批结果：" prop="">
                <el-input placeholder="驳回" v-model="ruleForm.dictName" class="my-custom custom-input">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="审批原因：" prop="">
                <el-input placeholder="审批驳回原因" v-model="ruleForm.dictName" class="my-custom custom-input">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="任务完成质量：" prop="">
                <div class="defect">
            
                  <el-rate v-model="value1"></el-rate>
            </div>
              </el-form-item>
            </el-col>
          </el-row>


        </el-form>
      </div>
    </el-dialog>




    <!-- 变配电站选择 -->
    <dialogTable :value="value" :titleShow="titleShow" @MYinput="MYinput"></dialogTable>


  </div>
</template>

<script>
import { boardList } from '../../api/dailyWork.js'
// import demo from "./demo.vue"
import dialogTable from "./commponents/dialogTable.vue"
export default {
  name: 'Administration',
  data() {
    return {
      value1:null,
      value: false,
      titleShow: 0,
      formInline: {
        title: '',//标题
        content: '',//内容
        createBy: '',//创建人
        updateTime: '',//创建时间
        checkList: []
      },
      tableData: [
        {
          name: '变配电站0001',
          model: 'R2303290001',
          number: '负责人',
          number2: '执行人',
          email: '2023-05-28',
          email1: '2023-05-2',
          email2: '待办',
          email3: '超时完成',
          email4: '30',
          email5: '2',

        },
        {
          name: '变配电站0001',
          model: 'R2303290001',
          number: '负责人',
          number2: '执行人',
          email: '2023-05-28',
          email1: '2023-05-2',
          email2: '待办',
          email3: '超时完成',
          email4: '30',
          email5: '2',

        },
        {
          name: '变配电站0001',
          model: 'R2303290001',
          number: '负责人',
          number2: '执行人',
          email: '2023-05-28',
          email1: '2023-05-2',
          email2: '待办',
          email3: '超时完成',
          email4: '30',
          email5: '2',

        },

      ],

      deleteDialogType: 0,
      centerDialogVisible: false,
      replyDialogVisible: false,
      implementDialogVisible: false,
      prevButtonText: '上一页',
      nextButtonText: '下一页',
      paginationEndIpt: '',
      pageSize: 10,
      currentPage: 1,
      total: 40,
      textarea: '',

    
      checkedNodes: [],
      selectAll: false,
      selectAllText: '全选',
      treeTitle: [],
      treeText: '',
      selectContent: '',
      inputValue: '',
      defaultProps: {
        children: 'children',
        id: 'id',
        label: 'label'
      },
      ruleForm: {
        dictName: '',
        dictCode: '',
        state: '',
        dictDesc: '',
        dictLevel: 1

      },
      rules: {
        dictName: [
          { required: true, message: '请输入文件类型', trigger: 'blur' },
        ],
        dictCode: [
          { required: true, message: '请选择文件', trigger: 'blur' },
        ],

      }


    };
  },
  components: { dialogTable },

  mounted() {
    // let pageText = document.getElementsByClassName('el-pagination__jump')[0]
    // pageText.childNodes[0].nodeValue = '跳至'
    // pageText.childNodes[2].nodeValue = '页'
    // this.getList()

  },
  computed: {

  },


  methods: {
    //执行情况
    executionDialog(){
      this.implementDialogVisible=true

    },
    // dialogTable 返回的数据
    MYinput(value) {
      this.value = value
    },
    titleNO() {
      this.value = true
      this.titleShow = 1
    },

    // 打开dialogTable的方法
    openDialog() {
      this.value = true
      this.titleShow = 0

    },
    // tree搜索
    handleSearch() {

    },
    handleCheck(checkedNodes, event) {
      this.checkedNodes = checkedNodes;
      // this.updateSelectAllState();
      console.log(this.checkedNodes);
    },
    //列表查询
    getList() {
      let obj = {
        ...this.formInline,
        page: this.currentPage,
        pageSize: this.pageSize
      }
      boardList(obj).then(res => {
        // console.log(res);
        if (res.code == 200) {
          this.tableData = res.data
        }
      })
    },
    //导出
    replyDialog() {
      // console.log(el.data);
      this.centerDialogVisible = true
    },
    //查询列表
    onSubmit() {
      console.log('submit!');
    },

    //打开意见弹窗
    openViewDialog(el, type) {
      console.log(el);
      this.replyDialogVisible = true
      this.deleteDialogType = type

    },
    //分页相关function
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.paginationEnd()
      this.getList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.paginationEndIpt = val
      console.log(`当前页: ${val}`);
      console.log(this.currentPage);
      this.getList()
    },
    //首页
    startHome() {
      this.currentPage = 1

    },
    //尾页
    endHome() {
      const lastPage = Math.ceil(this.total / this.pageSize);
      this.currentPage = lastPage;
    },
    //页数输入时
    paginationEnd() {
      const lastPage = Math.ceil(this.total / this.pageSize);
      this.paginationEndIpt = this.paginationEndIpt < lastPage ? this.paginationEndIpt : lastPage
    },
    //跳转
    jumpPage() {
      this.currentPage = this.paginationEndIpt
      // this.paginationEndIpt=''
    },
    //点击父节点
    titleTree(item) {
      // this.treeTitle.push(item.label)
      // this.treeTitle = [...new Set(this.treeTitle)];
      console.log(this.treeTitle);
      // const index = this.treeTitle.findIndex(item => item.label === item.label);
      const index = this.treeTitle.indexOf(item.label);
      if (index !== -1) {
        // 对象已存在于数组中，删除它
        this.treeTitle.splice(index, 1);
      } else {
        // 对象不存在于数组中，添加它
        this.treeTitle.push(item.label);
      }

    },
    // 点击子节点
    treeContent(item, content) {
      console.log(item, content);
      this.treeText = item.label

      this.selectContent = content.label
    }


  },
};
</script >
<style src="@/utils/style/table.css" scoped></style>
<style scoped>
/* import */
.content {
  width: 100%;
  height: 100%;
  background: url(../../assets/images/rightBG.png);
  background-repeat: no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
  display: flex;

}

.contentL {
  width: 400px;
  margin-top: 10px;
  padding: 28px 0 60px 17px;
  box-sizing: border-box;
  /* background: #071536; */

}

.contentR {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.prompt {
  padding: 28px 0 0 17px;
  font-size: 16px;
  color: #ebf4ff;
  display: flex;
  align-items: center;
}

.promptText {
  margin-left: 10px;
}

.el-input>>>.el-input__inner {
  background-color: #061b45;
  border: 1px solid #09295b;
  color: #AED6FF;
}

.el-form-item>>>.el-form-item__label {
  font-size: 12px;
  color: #AED6FF;
}

.custom-input ::placeholder {
  color: #AED6FF;
}


.custom-button {
  background: #176ad2;
}

.searchContent {
  margin: 11px 25px 16px 26px;
}

.search {
  display: flex;
  justify-content: space-between;
  padding: 14px 26px 13px 20px;
  background: #061C45;
}

.custom-pager-text {
  margin-right: 5px;
}

.custom-page-input {
  width: 60px;
  margin-right: 5px;
}

.custom-page-button {
  padding: 5px 10px;
}


.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 21px;
}

.btn {
  width: 60px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  background: rgba(255, 255, 255, 0);
  border: 1px solid #0b2653;
  border-radius: 2px;
  color: #92b5da;

}

.btn:hover {
  color: #ffffff;
}

.el-pagination>>>.btn-prev,
.el-pagination>>>.btn-next {
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

.el-pagination>>>.el-pager li {
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

.el-pagination>>>.el-pager .active {
  background: #176AD2;
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

::v-deep .el-input__inner {
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;

}

.endBtn {
  margin-left: -15px;
  margin-right: 10px;
}

.paginationEnd {
  display: flex;
  justify-content: center;
  align-items: center;

}

.paginationEndIpt {
  width: 30px;
  margin: 0 7px;
  padding: 0;
  color: #92b5da;
  /* height: 16px; */
}

.paginationEndIpt>>>.el-input__inner {
  padding: 0 5px;
}

.confirm {
  margin-left: 10px;
  background: #176AD2;
  color: #d7d7d7;
}

/* 弹窗 */
::v-deep .el-dialog {
  background-color: #071734;
  font-size: 16px;
}

::v-deep .el-dialog__header {
  background: #05153A;
  border-bottom: 1px solid #09295b;
}

::v-deep .el-dialog__title {
  font-size: 16px;
  color: #fff;
}

.dialog-footer {
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #000;
}

.dialogBtn {
  width: 100px;
  height: 35px;
  background: #2142A1;
  border: 0;
  color: #fff;
}

.newCenter {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.newCenterText {
  width: 80px;
  color: #C9D4D4;
}

::v-deep .el-textarea__inner {
  background-color: #061D4A;
  color: #AED6FF;
  border: 1px solid #09295b;
}

.custom-input-textarea ::placeholder {
  color: #667597;
}

.newCenterKey {
  width: 50px;
  color: #C9D4D4;
}

/* tree样式修改 */
::v-deep .el-tree {
  background: #03183F !important;
  color: #aed6ff;
  padding-bottom: 10px;
}


::v-deep .el-tree-node {
  background: #03183F !important;
  color: #aed6ff;
}

::v-deep .is-current {
  background: #03183F !important;
  color: #FFC74A;
}

::v-deep .is-checked {
  background: #03183F !important;
  /* color: #FFC74A; */
}

::v-deep .el-tree-node__content:hover {
  background: #03183F;
  /* color: #FFC74A; */
}

::v-deep .el-tree-node__content:focus {
  background: #03183F !important;
}

::v-deep.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
  background-color: #03183F;
}

::v-deep .el-tree-node {
  background-color: #03183F;

}

.tree {
  margin-top: 21px;
  /* background: #071734 ; */
  color: #aed6ff;

}

.treeContent {
  height: 32px;
  line-height: 32px;
  padding-left: 62px;
  font-size: 14px;
}

.treeContent:hover {
  color: #FFC74A;
}

.selectContent {
  color: #FFC74A;
}

.treeTitle {
  height: 32px;
  line-height: 32px;
  margin-left: 32px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.treeTitle img {
  width: 10px;
  height: 7px;
  margin-right: 5px;

}

.titleImg {
  transform: rotate(90deg);
}

.contentLtitle {
  color: #d8e3ff;
  font-size: 14px;
  margin-bottom: 20px;
}

.customSelect {
  color: #d8e3ff;
  margin-bottom: 10px;
}

::v-deep .el-tree-node:focus>.el-tree-node__content {
  background-color: #03183F !important;
}

::v-deep .el-checkbox__inner {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #083B7A;
  /* background-color: rgba(255, 255, 255, 0); */
}

.inputValue {
  width: 210px;
  margin-bottom: 10px;
}

.deleteCenter {
  font-size: 16px;
  color: #EBEDF1;
}

.custom-select>>>.el-select-dropdown {
  background: #041C4C !important;
  border: 1px solid #09295b;
  color: #aed6ff;
}

::v-deep .el-select-dropdown__item.hover {
  background: #041C4C !important;
  color: #fff;
}

::v-deep .el-select-dropdown__item {
  color: #aed6ff;
}

::v-deep .el-select-dropdown__item.selected {
  color: #fff;
}

::v-deep .el-popper[x-placement^=bottom] .popper__arrow::after {
  top: 0;
  border-bottom-color: #041C4C;
}

.custom-select ::placeholder {
  color: #AED6FF;
}

::v-deep .el-date-editor ::placeholder {
  color: #AED6FF;
}

.el-select {
  width: 100%;
}

.custom-picker {
  width: 100%;
  /* background: #05153A; */
}

.custom-picker>>>.el-input__inner {
  background: #071734;

}

.my-custom>>>.el-input__inner {
  background: #071734;
}

.my-custom>>>.el-textarea__inner {
  background: #071734;
}




::v-deep .el-picker-panel {
  background: #041C4C;
  color: #aed6ff;
  border-color: #041C4C;
}

::v-deep .el-date-picker__header-label {
  color: #aed6ff;
}

::v-deep .el-picker-panel__icon-btn {
  color: #aed6ff;
}

::v-deep .el-date-table th {
  color: #aed6ff;
  border-color: #aed6ff;
}

::v-deep .el-checkbox {
  padding: 0px 20px 0px 10px;
  border-radius: 2px;
  border: 1px solid #09295b;
  color: #aed6ff;
  background-color: #041c4c;
}

::v-deep .is-checked {
  /* border: 1px solid #176ad2; */
  background-color: #041c4c;
  color: #aed6ff;
}

::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
  color: #aed6ff;
}

::v-deep .el-input-group__append {
  background: #143168;
  color: #fff;
  border: 0;
  cursor: pointer;
}

::v-deep .el-tag.el-tag--info {
  background: #03173C;
  color: #aed6ff;
  border: 1px solid #083B7A;
}

::v-deep .selected {
  background: #041C4C;
}
.dialogHeader{
  height: 550px;
  overflow: auto;
}

.defect{
  color: #aed6ff;
  border: 1px solid #06224e;
  box-sizing: border-box;
  padding-right: 100px;
  padding: 15px 0;
}
.defectTitle{
  border-bottom: 1px solid #06224e ;
  padding:10px  0 0 15px ;
}
.defectContent{
  padding:10px  0 0 15px ;

}
.dialogHeader::-webkit-scrollbar {
  width: 8px;
  /* 设置滚动条高度 */
  background-color: #09234E;
  /* 设置滚动条背景色 */
}

.dialogHeader::-webkit-scrollbar-thumb {
  background-color: #003d74;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}


</style>